import { Dispatch } from "redux";
import { SearchUserActions, SEARCH_USER_START, SearchUserStart, SearchUserError, SEARCH_USER_ERROR, SearchUserSuccess, SEARCH_USER_SUCCESS } from "./types";
import { UserInfo } from "../types";
import { ApplicationStore } from "../..";
import { checkUnauthorized } from "../authentication/actions";
import { API_URL } from "../../config";

export const SearchUserStartAction = (): SearchUserStart => {
    return {
        type: SEARCH_USER_START,
    }
}

export const SearchUserSuccessAction = (user: UserInfo[], paginationToken: string): SearchUserSuccess => {
    return {
        type: SEARCH_USER_SUCCESS,
        payload: { user, paginationToken }
    }
}

export const SearchUserErrorAction = (error: string): SearchUserError => {
    return {
        type: SEARCH_USER_ERROR,
        payload: { error }
    }
}

interface SearchUserRequest {
    filter?: string; limit?: number; paginationToken?: string;
}
interface SearchUserResponse {
    users: UserInfo[];
    paginationToken: string;
}

export const searchUser = (filter: string, limit: number, nextToken: string) => async (dispatch: Dispatch<SearchUserActions>) => {
    const { Login: { userInfo }, User: { user: currentUser } } = ApplicationStore.getState();
    if (!userInfo) {
        return;
    }
    dispatch(SearchUserStartAction());
    const { token } = userInfo;
    const data: SearchUserRequest = {};
    if (filter) {
        data.filter = filter.indexOf("@") === -1 ? `username^="${filter}"` : `email^="${filter}"`;

    }
    if (limit) {
        data.limit = limit;
    }
    if (nextToken) {
        data.paginationToken = nextToken;
    }

    let dataQuery = Object.keys(data).map(key => {
        return key + "=" + encodeURIComponent(data[key as keyof SearchUserRequest]!.toString());
    }).join("&");
    if (dataQuery !== "") {
        dataQuery = "?" + dataQuery;
    }

    try {
        const res = await fetch(API_URL + "users" + dataQuery, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "authorization": "bearer " + token
            },
        });
        if (res.status === 200) {
            const data = await res.json();
            const { users: user, paginationToken } = data as SearchUserResponse;
            let newUsers = (nextToken === "") ? user : [...currentUser, ...user];
            dispatch(SearchUserSuccessAction(newUsers, paginationToken));
        } else {
            await dispatch<any>(checkUnauthorized(res));
            dispatch(SearchUserErrorAction(res.status.toString()));
        }
    } catch (e) {
        dispatch(SearchUserErrorAction(e.toString()))
    }
}