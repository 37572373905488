import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Box,
  Typography,
  LinearProgress,
  TableRow,
  TableCell,
  ListItem,
  ListItemText,
  Divider,
  List,
  Tabs,
  Tab,
  Button,
} from "@material-ui/core";

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AppState } from "../../store";
import { ErrorBlock } from "../../ErrorBlock";
import "../../styling/paginationTheme.css";
import { getUserdetailsByName } from "../../store/getUserdetailsByName/actions";
import { HomeserverTable } from "../homeserver/HomeserverTable";
import { HomeserverTblRowItems } from "../homeserver/HomeserverTblRowItems";
import { HomeserverInfo } from "../../store/types";
import { DeleteWaterheaters } from "../waterheaters/DeleteWaterheaters";
import { DeleteUser } from "./DeleteUser";
import { UserDetailsAsText } from "./UserDetailsAsText";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      fontWeight: "lighter",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    list: {
      width: 250,
    },
    tableCellHead: {
      width: "50%",
      textAlign: "right",
      paddingRight: theme.spacing(1),
    },
    pagination: {
      paddingTop: 10,
      justifyContent: "flex-end",
    },
    button: {
      paddingLeft: 20,
    },
  }),
);

type TabValues = "homeserver" | "waterheater" | "user";

const getFromStoreMapping = ({ UserDetails }: AppState) => {
  return UserDetails;
};

interface UserDetailProps {
  username: string;
}

export function UserDetails({ username }: UserDetailProps) {
  const dispatch = useDispatch();
  const classes = useStyles();

  React.useEffect(() => {
    dispatch(getUserdetailsByName(username));
  }, [username, dispatch]);

  const {
    loading,
    error,
    homeservers,
    waterheaters,
    user: users,
  } = useSelector(getFromStoreMapping, shallowEqual);

  const childs = waterheaters.map(
    ({ waterheater_device_id, remote_device_id, name }) => {
      return (
        <List key={waterheater_device_id}>
          <ListItem>
            <ListItemText>
              <b>Durchlauferhitzer ID: </b> {waterheater_device_id}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>remote device ID: </b> {remote_device_id}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Name: </b> {name}
            </ListItemText>
          </ListItem>
          <DeleteWaterheaters />
          <Divider />
        </List>
      );
    },
  );

  const user = users[0] || {};

  const [activeTabValue, setActiveTab] = useState<TabValues>("user");

  const openDetailView = (homeserver: HomeserverInfo) => {
    document.location.href =
      document.location.origin + "/HomeserverOverview/" + homeserver.id;
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: TabValues) => {
    event.stopPropagation();
    event.preventDefault();
    setActiveTab(newValue);
  };

  return (
    <div className={classes.root}>
      <Box mb={3}>
        <Typography variant="h4" color="primary" className={classes.paper}>
          Details von {user.username}
        </Typography>
      </Box>
      <ErrorBlock error={error} />

      {loading ? (
        <LinearProgress color="primary" />
      ) : (
        <>
          <Tabs
            value={activeTabValue}
            centered
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            <Tab disableFocusRipple value="user" label="Nutzer" />
            <Tab disableFocusRipple value="homeserver" label="Homeserver" />
            <Tab
              disableFocusRipple
              value="waterheater"
              label="Durchlauferhitzer"
            />
          </Tabs>
          {activeTabValue === "homeserver" ? (
            <HomeserverTable>
              {homeservers.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={2} className={classes.tableCellHead}>
                    Keine Homeserver gefunden
                  </TableCell>
                </TableRow>
              ) : (
                <HomeserverTblRowItems
                  homeservers={homeservers}
                  onClick={openDetailView}
                />
              )}
            </HomeserverTable>
          ) : null}
          {activeTabValue === "user" ? (
            <>
              <List>
                <ListItemText className={classes.button}>
                  <UserDetailsAsText {...user} />
                </ListItemText>
              </List>
              <DeleteUser />
              <Divider />
            </>
          ) : null}

          {activeTabValue === "waterheater" ? <List>{childs}</List> : null}
          <Button
            className={classes.button}
            component="a"
            href={document.location.origin + "/HomeserverOverview"}
            size="large"
          >
            Zurück zur Übersicht
          </Button>
        </>
      )}
    </div>
  );
}
