import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import { HomeserverInfo } from "../../store/types";

interface HomeserverTblRowItemProps {
  homeservers: HomeserverInfo[];
  onClick: (homeserver: HomeserverInfo) => void;
}

export function HomeserverTblRowItems({
  homeservers,
  onClick,
}: HomeserverTblRowItemProps) {
  const childs = homeservers.map(homeserver => {
    const {
      home_server_name,
      home_server_device_id,
      state,
      internet_connection_status,
      internet_connection_status_change_timestamp,
    } = homeserver;
    return (
      <TableRow
        key={home_server_device_id}
        style={{ cursor: "pointer" }}
        onClick={onClick.bind(null, homeserver)}
      >
        <TableCell style={{ width: "10%" }}>{home_server_name}</TableCell>
        <TableCell style={{ width: "10%" }}>{home_server_device_id}</TableCell>
        <TableCell style={{ width: "10%" }}>{state}</TableCell>
        <TableCell style={{ width: "10%" }}>
          {internet_connection_status}
        </TableCell>
        <TableCell style={{ width: "10%" }}>
          {internet_connection_status_change_timestamp}
        </TableCell>
      </TableRow>
    );
  });
  return <>{childs}</>;
}
