import { SEARCH_USER_START, SEARCH_USER_SUCCESS, SEARCH_USER_ERROR, UserState, SearchUserActions, } from "./types";

const initalState: UserState = {
    loading: false,
    user: [],
    paginationToken: "",
    total: 0,
}

export const UserReducer = (state: UserState = initalState, action: SearchUserActions): UserState => {
    switch (action.type) {
        case SEARCH_USER_START: return {
            ...state,
            loading: true
        }
        case SEARCH_USER_SUCCESS: return {
            ...state,
            user: action.payload.user,
            paginationToken: action.payload.paginationToken,
            loading: false,
            error: undefined
        }
        case SEARCH_USER_ERROR: return {
            ...state,
            loading: false,
            user: [],
            total: 0,
            error: action.payload.error
        }
        default: return state
    }
}