import { Dispatch } from "redux";
import { GetHomeserverActions, GET_HOMESERVER_START, GetHomeserverStart, GetHomeserverError, GET_HOMESERVER__ERROR, GetHomeserverSuccess, GET_HOMESERVER_SUCCESS } from "./types";
import { ApplicationStore } from "../..";
import { checkUnauthorized } from "../authentication/actions";
import { HomeserverInfo } from "../types";
import { API_URL } from "../../config";

export const GetGetHomeserverStartAction = (): GetHomeserverStart => {
    return {
        type: GET_HOMESERVER_START,
    }
}

export const GetGetHomeserverSuccessAction = (homeservers: HomeserverInfo[], total: number): GetHomeserverSuccess => {
    return {
        type: GET_HOMESERVER_SUCCESS,
        payload: { homeservers, total }
    }
}

export const GetGetHomeserverErrorAction = (error: string): GetHomeserverError => {
    return {
        type: GET_HOMESERVER__ERROR,
        payload: { error }
    }
}

interface HomeServerRequest {
    q?: string; offset?: number; limit?: number;
}
interface HomeServerResponse {
    total: number;
    homeservers: HomeserverInfo[]
}

export const getHomeserver = (q?: string, offset?: number, limit?: number) => async (dispatch: Dispatch<GetHomeserverActions>) => {
    const { Login: { userInfo } } = ApplicationStore.getState();
    if (!userInfo) {
        return;
    }
    dispatch(GetGetHomeserverStartAction());
    const { token } = userInfo;
    const data: HomeServerRequest = {};
    if (q) {
        data.q = "%" + q + "%";

    }
    if (offset) {
        data.offset = offset;
    }
    if (limit) {
        data.limit = limit;
    }

    let dataQuery = Object.keys(data).map(key => {
        return key + "=" + encodeURIComponent(data[key as keyof HomeServerRequest]!.toString());
    }).join("&");
    if (dataQuery !== "") {
        dataQuery = "?" + dataQuery;
    }

    try {
        const res = await fetch(API_URL + "homeservers" + dataQuery, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "authorization": "bearer " + token
            },
        });
        if (res.status === 200) {
            const data = await res.json();
            const { homeservers, total } = data as HomeServerResponse;
            dispatch(GetGetHomeserverSuccessAction(homeservers, total));
        } else {
            await dispatch<any>(checkUnauthorized(res));
            dispatch(GetGetHomeserverErrorAction(res.status.toString()));
        }
    } catch (e) {
        dispatch(GetGetHomeserverErrorAction(e.toString()))
    }
}