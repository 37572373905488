import { AuthenticationState, LoginActions, LOGIN_SUCCESS, LOGIN_START, LOGIN_ERROR, LOGOUT_SUCCESS } from "./types";
import * as Cookies from "es-cookie";

const initalState: AuthenticationState = {
    loading: false
}

const cookieValue = Cookies.get("authentication");
if (cookieValue) {
    const { email, token, firstName, lastName } = JSON.parse(cookieValue);
    if (token) {
        initalState.userInfo = { email, token, firstName, lastName };
    }
}

export const LoginReducer = (state: AuthenticationState = initalState, action: LoginActions): AuthenticationState => {
    switch (action.type) {
        case LOGIN_SUCCESS: return {
            ...state, userInfo: action.payload.userInfo, loading: false,
            error: undefined
        }
        case LOGIN_START: return {
            ...state, loading: true,
            userInfo: undefined,
            error: undefined
        }
        case LOGIN_ERROR: return {
            ...state, error: action.payload.error,
            loading: false,
            userInfo: undefined
        }
        case LOGOUT_SUCCESS: return {
            loading: false,
            error: undefined,
            userInfo: undefined
        }
        default: return state
    }
}