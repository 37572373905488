import React from "react";
import { Table, TableBody, TableRow, TableCell } from "@material-ui/core";

export function WaterheatersTable({ children }: { children: JSX.Element }) {
  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>
            <b>remote device ID</b>
          </TableCell>
          <TableCell>
            <b>Name</b>
          </TableCell>
          <TableCell>
            <b>zugehöriger Homeserver</b>
          </TableCell>
        </TableRow>
        {children}
      </TableBody>
    </Table>
  );
}
