import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Box,
  Typography,
  TableRow,
  TableCell,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { searchWaterheaters } from "../../store/searchWaterheaters/actions";
import { AppState } from "../../store";
import Pagination from "react-bootstrap/Pagination";
import { ErrorBlock } from "../../ErrorBlock";
import debounce from "lodash/debounce";

import { WaterHeaterDetailsPage } from "./WaterHeaterDetailsPage";
import { WaterHeaterDetails } from "./WaterHeaterDetails";
import { RouteComponentProps, withRouter } from "react-router";
import { ClageRoutes } from "../../Dashboard";
import { WaterheatersTable } from "./WaterheatersTable";
import { WaterheatersTblRowItems } from "./WaterheatersTblRowItems";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      fontWeight: "lighter",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    list: {
      width: 250,
    },
    tableCellHead: {
      width: "50%",
      textAlign: "right",
      paddingRight: theme.spacing(1),
    },
    pagination: {
      paddingTop: 10,
      justifyContent: "flex-end",
    },
  }),
);

const getFromStoreMapping = ({ Durchlauferhitzer }: AppState) => {
  return Durchlauferhitzer;
};

export function DurchlauferhitzerOverviewRouted() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [detailsPage, setDetailsPage] = useState<number | undefined>(undefined);

  const durchlauferhitzerPerPage = 25;

  const { durchlauferhitzer, total, loading, error } = useSelector(
    getFromStoreMapping,
    shallowEqual,
  );

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(total / durchlauferhitzerPerPage); i++) {
    pageNumbers.push(i);
  }

  const onPageClick = React.useCallback(
    (nr: number) => () => {
      setCurrentPage(nr);
    },
    [],
  );
  const pages = pageNumbers.map(nr => {
    return (
      <Pagination.Item
        active={nr === currentPage}
        onClick={onPageClick(nr)}
        key={nr}
      >
        {nr}
      </Pagination.Item>
    );
  });

  const [searchValue, setSearchValue] = useState("");
  const [searchValueDebounced, setSearchValueDebounced] = useState("");

  const onChangeQ = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const setDebounced = React.useCallback(
    debounce((value: string) => {
      setCurrentPage(1);
      setSearchValueDebounced(value);
    }, 1000),
    [],
  );

  React.useEffect(() => {
    setDebounced(searchValue);
  }, [searchValue, setDebounced]);

  React.useEffect(() => {
    dispatch(
      searchWaterheaters(
        searchValueDebounced,
        (currentPage - 1) * 10,
        durchlauferhitzerPerPage,
      ),
    );
  }, [searchValueDebounced, currentPage, dispatch]);

  const openDetailView = (id: number) => {
    document.location.href =
      document.location.origin + "/DurchlauferhitzerOverview/" + id;
  };

  const onCloseWaterHeaterDetailView = () => {
    setDetailsPage(undefined);
  };

  return (
    <div className={classes.root}>
      <WaterHeaterDetailsPage
        onClose={onCloseWaterHeaterDetailView}
        id={detailsPage}
      />

      <Box mb={3}>
        <Typography variant="h4" color="primary" className={classes.paper}>
          Durchlauferhitzer {total} Ergebnisse
        </Typography>
      </Box>

      <TextField
        fullWidth={true}
        onChange={onChangeQ}
        placeholder="Suche nach remote device ID (und Durchlauferhitzer Name) "
        value={searchValue}
      ></TextField>

      <ErrorBlock error={error} />

      <Pagination className={classes.pagination}>{pages}</Pagination>

      {loading ? (
        <LinearProgress color="primary" />
      ) : (
        <WaterheatersTable>
          {durchlauferhitzer.length === 0 ? (
            <TableRow>
              <TableCell colSpan={2} className={classes.tableCellHead}>
                Keine Durchlauferhitzer gefunden
              </TableCell>
            </TableRow>
          ) : (
            <WaterheatersTblRowItems
              durchlauferhitzer={durchlauferhitzer}
              onClick={openDetailView}
            />
          )}
        </WaterheatersTable>
      )}
      <Pagination className={classes.pagination}>{pages}</Pagination>
    </div>
  );
}

function DurchlauferhitzerOverviewSwitch(
  props: RouteComponentProps<ClageRoutes>,
) {
  const detailId = parseInt(props.match.params.detailId, 10);
  console.log(detailId);
  if (Number.isFinite(detailId)) {
    return <WaterHeaterDetails homeServerId={detailId} />;
  } else {
    return <DurchlauferhitzerOverviewRouted />;
  }
}

export const DurchlauferhitzerOverview = withRouter(
  DurchlauferhitzerOverviewSwitch,
);
