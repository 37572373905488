import { Dispatch } from "redux";
import { SearchWaterheatersActions, SEARCH_WATERHEATERS_START, SearchWaterheatersStart, SearchWaterheatersErrorError, SEARCH_WATERHEATERS_ERROR, SearchWaterheatersSuccess, SEARCH_WATERHEATERS_SUCCESS, SearchWaterheatersResult } from "./types";
import { ApplicationStore } from "../..";
import { checkUnauthorized } from "../authentication/actions";
import { API_URL } from "../../config";

export const SearchWaterheatersStartAction = (): SearchWaterheatersStart => {
    return {
        type: SEARCH_WATERHEATERS_START,
    }
}

export const SearchWaterheatersSuccessAction = (durchlauferhitzer: SearchWaterheatersResult[], total: number): SearchWaterheatersSuccess => {
    return {
        type: SEARCH_WATERHEATERS_SUCCESS,
        payload: { durchlauferhitzer, total }
    }
}

export const SearchWaterheatersErrorAction = (error: string): SearchWaterheatersErrorError => {
    return {
        type: SEARCH_WATERHEATERS_ERROR,
        payload: { error }
    }
}

interface SearchWaterheatersRequest {
    q?: string; offset?: number; limit?: number;
}
interface SearchWaterheatersResponse {
    total: number;
    waterheaters: SearchWaterheatersResult[]
}

export const searchWaterheaters = (q?: string, offset?: number, limit?: number) => async (dispatch: Dispatch<SearchWaterheatersActions>) => {
    const { Login: { userInfo } } = ApplicationStore.getState();
    if (!userInfo) {
        return;
    }
    dispatch(SearchWaterheatersStartAction());
    const { token } = userInfo;
    const data: SearchWaterheatersRequest = {};
    if (q) {
        data.q = "%" + q + "%";

    }
    if (offset) {
        data.offset = offset;
    }
    if (limit) {
        data.limit = limit;
    }

    let dataQuery = Object.keys(data).map(key => {
        return key + "=" + encodeURIComponent(data[key as keyof SearchWaterheatersRequest]!.toString());
    }).join("&");
    if (dataQuery !== "") {
        dataQuery = "?" + dataQuery;
    }

    try {
        const res = await fetch(API_URL + "waterheaters" + dataQuery, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "authorization": "bearer " + token
            },
        });
        if (res.status === 200) {
            const data = await res.json();
            const { waterheaters: durchlauferhitzer, total } = data as SearchWaterheatersResponse;
            dispatch(SearchWaterheatersSuccessAction(durchlauferhitzer, total));
        } else {
            await dispatch<any>(checkUnauthorized(res));
            dispatch(SearchWaterheatersErrorAction(res.status.toString()));
        }
    } catch (e) {
        dispatch(SearchWaterheatersErrorAction(e.toString()))
    }
}