import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const greenTheme: ThemeOptions = {
    overrides: {
        MuiPaper: {
            root: {
                color: '#009D4B',
            },
        },

        MuiButton: {
            label: {
                color: '#0009D4B',

            },
        },
        MuiButtonBase: {
            root: {
                color: '#009D4B',
            },
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },

    typography: {
        button: {
            textTransform: "none",
        },
        // body1: {
        //     color: '#009D4B',
        // },
    },

    palette: {
        primary: {
            main: '#009D4B',
        },
        secondary: {
            main: '#f00',
        },
    }
};

export const theme = createMuiTheme(
    greenTheme
);