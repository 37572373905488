import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Box,
  Typography,
  TableRow,
  TableCell,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getHomeserver } from "../../store/getHomeserver/actions";
import { AppState } from "../../store";
import Pagination from "react-bootstrap/Pagination";
import { ErrorBlock } from "../../ErrorBlock";
import debounce from "lodash/debounce";
import { HomeserverInfo } from "../../store/types";

import { HomeserverTblRowItems } from "./HomeserverTblRowItems";
import { HomeserverTable } from "./HomeserverTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      fontWeight: "lighter",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    list: {
      width: 250,
    },
    tableCellHead: {
      width: "50%",
      textAlign: "right",
      paddingRight: theme.spacing(1),
    },
    pagination: {
      paddingTop: 10,
      justifyContent: "flex-end",
    },
  }),
);

const getFromStoreMapping = ({ Homeserver }: AppState) => {
  return Homeserver;
};

export function HomeserverOverviewRouted() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);

  const homeserverPerPage = 25;

  const { homeservers, total, loading, error } = useSelector(
    getFromStoreMapping,
    shallowEqual,
  );

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(total / homeserverPerPage); i++) {
    pageNumbers.push(i);
  }

  const onPageClick = React.useCallback(
    (nr: number) => () => {
      setCurrentPage(nr);
    },
    [],
  );
  const pages = pageNumbers.map(nr => {
    return (
      <Pagination.Item
        active={nr === currentPage}
        onClick={onPageClick(nr)}
        key={nr}
      >
        {nr}
      </Pagination.Item>
    );
  });

  const [searchValue, setSearchValue] = useState("");
  const [searchValueDebounced, setSearchValueDebounced] = useState("");

  const onChangeQ = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const setDebounced = React.useCallback(
    debounce((value: string) => {
      setCurrentPage(1);
      setSearchValueDebounced(value);
    }, 1000),
    [],
  );

  React.useEffect(() => {
    setDebounced(searchValue);
  }, [searchValue, setDebounced]);

  React.useEffect(() => {
    dispatch(
      getHomeserver(
        searchValueDebounced,
        (currentPage - 1) * 10,
        homeserverPerPage,
      ),
    );
  }, [searchValueDebounced, currentPage, dispatch]);

  const openDetailView = (homeserver: HomeserverInfo) => {
    document.location.href =
      document.location.origin + "/HomeserverOverview/" + homeserver.id;
  };

  return (
    <div className={classes.root}>
      <Box mb={3}>
        <Typography variant="h4" color="primary" className={classes.paper}>
          Homeserver {total} Ergebnisse
        </Typography>
      </Box>

      <TextField
        fullWidth={true}
        onChange={onChangeQ}
        placeholder="Suche nach Homeserver Name oder Homeserver ID "
        value={searchValue}
      ></TextField>

      <ErrorBlock error={error} />

      {loading ? (
        <LinearProgress color="primary" />
      ) : (
        <>
          <Pagination className={classes.pagination}>{pages}</Pagination>
          <HomeserverTable>
            {homeservers.length === 0 ? (
              <TableRow>
                <TableCell colSpan={2} className={classes.tableCellHead}>
                  Keine Homeserver gefunden
                </TableCell>
              </TableRow>
            ) : (
              <HomeserverTblRowItems
                homeservers={homeservers}
                onClick={openDetailView}
              />
            )}
          </HomeserverTable>
          <Pagination className={classes.pagination}>{pages}</Pagination>
        </>
      )}
    </div>
  );
}
