import { RouteComponentProps } from "react-router";

import React from "react";
import { connect } from "react-redux";
import { AppState } from "./store";
import { ClageRoutes } from "./Dashboard";
// import { IApplicationState, IConnectedReduxProps } from "../../store";

interface IProps {
  authenticated: boolean;
}

const RequireAuthComponentFunc = (
  ComposedComponent: React.ComponentType,
  props: RouteComponentProps<ClageRoutes>,
) => {
  class Authentication extends React.Component<IProps> {
    public componentDidMount() {
      if (!this.props.authenticated) {
        document.location.replace(document.location.origin + "/LogIn");
      }
    }

    public componentDidUpdate() {
      if (!this.props.authenticated) {
        document.location.replace(document.location.origin + "/LogIn");
      }
    }

    public render() {
      return this.props.authenticated ? (
        <ComposedComponent {...this.props} />
      ) : null;
    }
  }

  function mapStateToProps({ Login: { userInfo } }: AppState) {
    if (!userInfo) {
      //, JSON.stringify({ email, token, firstName, lastName }), { expires: 1 });
    }
    return { authenticated: !!userInfo, ...props };
  }

  return connect(mapStateToProps)(Authentication);
};

export default (ComposedComponent: React.ComponentType) => (
  props: RouteComponentProps<ClageRoutes>,
) => {
  const RequireAuthComponent = RequireAuthComponentFunc(
    ComposedComponent,
    props,
  );
  return <RequireAuthComponent />;
};
