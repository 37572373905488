import { SEARCH_WATERHEATERS_START, SEARCH_WATERHEATERS_SUCCESS, SEARCH_WATERHEATERS_ERROR, SearchWaterheaterState, SearchWaterheatersActions } from './types';

const initialState: SearchWaterheaterState = {
    loading: false,
    durchlauferhitzer: [],
    total: 0,
}

export const SearchWaterheatersReducer = (state: SearchWaterheaterState = initialState, action: SearchWaterheatersActions): SearchWaterheaterState => {
    switch (action.type) {
        case SEARCH_WATERHEATERS_START: return {
            ...state,
            loading: true
        }
        case SEARCH_WATERHEATERS_SUCCESS: return {
            ...state,
            durchlauferhitzer: action.payload.durchlauferhitzer,
            total: action.payload.total,
            loading: false,
            error: undefined
        }
        case SEARCH_WATERHEATERS_ERROR: return {
            ...state,
            loading: false,
            durchlauferhitzer: [],
            total: 0,
            error: action.payload.error
        }
        default: return state
    }
}