import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Box, Typography } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import PopperJs from "popper.js";
import { DeleteUser } from "../users/DeleteUser";
import { DeleteHomeserver } from "../homeserver/DeleteHomeserver";
import { DeleteWaterheaters } from "./DeleteWaterheaters";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      fontWeight: "lighter",
    },
    tableCell: {},
    tableCellHead: {
      width: "50%",
      fontWeight: "bold",
      textAlign: "right",
      paddingRight: theme.spacing(1),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
  }),
);

interface Props {
  children: React.ReactElement;
  open: boolean;
  value: number;
}

function ValueLabelComponent(props: Props) {
  const { children, open, value } = props;

  const popperRef = React.useRef<PopperJs | null>(null);
  React.useEffect(() => {
    if (popperRef.current) {
      popperRef.current.update();
    }
  });

  return (
    <Tooltip
      PopperProps={{
        popperRef,
      }}
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={value}
    >
      {children}
    </Tooltip>
  );
}

export function DetailViewDurchlauferhitzer() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <DeleteUser />
      <DeleteHomeserver />
      <DeleteWaterheaters />
      <Box mb={3}>
        <Typography variant="h4" color="primary" className={classes.paper}>
          Detailansicht
        </Typography>
      </Box>

      <Typography>Nutzer:</Typography>
      <Typography>Homeserver: {}</Typography>
      <Typography>Durchlauferhitzer:</Typography>
      <Typography gutterBottom>Temperatur verändern</Typography>
      <Slider
        ValueLabelComponent={ValueLabelComponent}
        aria-label="custom thumb label"
        defaultValue={20}
      />
    </div>
  );
}
