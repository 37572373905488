import { HomeserverInfo } from "../types";
import { SearchWaterheatersResult } from "../searchWaterheaters/types"
import { UserInfo } from "../types";

export interface UserDetailsInfo {
    username: string,
    emailVerified: boolean,
    email: string,
    userCreateDate: string,
    userLastModifiedDate: string,
    enabled: boolean,
    userStatus: string,
}

export interface UserDetailsState {
    loading: boolean;
    error?: string;
    waterheaters: SearchWaterheatersResult[];
    homeservers: HomeserverInfo[];
    user: UserInfo[];
    total: number;

    paginationToken: string;
}

export const USER_DETAILS_START = "USER_DETAILS_START";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_ERROR = "USER_DETAILS_ERROR";

export interface UserDetailsStart {
    type: typeof USER_DETAILS_START;
}

export interface UserDetailsSuccess {
    type: typeof USER_DETAILS_SUCCESS;
    payload: {
        waterheaters: SearchWaterheatersResult[];
        total: number;
        homeservers: HomeserverInfo[];
        user: UserInfo[];
    }
}

export interface UserDetailsError {
    type: typeof USER_DETAILS_ERROR;
    payload: {
        error: string;
    }
}

export type UserDetailsActions = UserDetailsStart | UserDetailsSuccess | UserDetailsError;