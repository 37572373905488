import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginBottom: 20,
      marginLeft: 15,
    },
    text: {
      color: "#000000",
    },
  }),
);

export function DeleteUser() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteUser = () => {
    setOpen(false);
  };

  return (
    <div className={classes.button}>
      <Button variant="outlined" onClick={handleClickOpen}>
        Nutzer Löschen
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.text} id="form-dialog-title">
          Sind Sie sich sicher den Nutzer: zu löschen?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Die Löschung des Nutzer kann nicht rückgängig gemacht werden. Alle
            dazugehörigen Homeserver und Durchlauferhitzer werden in diesem
            Schritt ebenfalls gelöscht.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Als Bestätigung die Email Adresse des Nutzers eingeben"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.text}>
            Abbrechen
          </Button>
          <Button onClick={handleDeleteUser} color="secondary">
            unwiderruflich löschen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
