import { GET_HOMESERVER_START, GET_HOMESERVER_SUCCESS, GET_HOMESERVER__ERROR, GetHomeserverState, GetHomeserverActions, } from "./types";

const initalState: GetHomeserverState = {
    loading: false,
    homeservers: [],
    total: 0,
}

export const HomeserverReducer = (state: GetHomeserverState = initalState, action: GetHomeserverActions): GetHomeserverState => {
    switch (action.type) {
        case GET_HOMESERVER_START: return {
            ...state,
            loading: true
        }
        case GET_HOMESERVER_SUCCESS: return {
            ...state,
            homeservers: action.payload.homeservers,
            total: action.payload.total,
            loading: false,
            error: undefined
        }
        case GET_HOMESERVER__ERROR: return {
            ...state,
            loading: false,
            homeservers: [],
            total: 0,
            error: action.payload.error
        }
        default: return state
    }
}