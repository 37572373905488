import { HomeserverInfo } from "../types";

export interface DeleteHomeserverState {
    loading: boolean;
    error?: string;
    homeservers: HomeserverInfo[];
    total: number;
}

export const DELETE_HOMESERVER_START = "DELETE_HOMESERVER_START";
export const DELETE_HOMESERVER_SUCCESS = "DELETE_HOMESERVER_SUCCESS";
export const DELETE_HOMESERVER_ERROR = "DELETE_HOMESERVER_ERROR";

export interface DeleteHomeserverStart {
    type: typeof DELETE_HOMESERVER_START;
}

export interface DeleteHomeserverSuccess {
    type: typeof DELETE_HOMESERVER_SUCCESS;
    payload: {
        homeservers: HomeserverInfo[];
        total: number;
    };
}

export interface DeleteHomeserverError {
    type: typeof DELETE_HOMESERVER_ERROR;
    payload: {
        error: string;
    };
}

export type DeleteHomeserverActions = DeleteHomeserverStart | DeleteHomeserverSuccess | DeleteHomeserverError;  