import { Dispatch } from "redux";
import { UserDetailsActions, USER_DETAILS_START, UserDetailsStart, UserDetailsError, USER_DETAILS_ERROR, UserDetailsSuccess, USER_DETAILS_SUCCESS, UserDetailsInfo } from "./types";
import { ApplicationStore } from "../..";
import { checkUnauthorized } from "../authentication/actions";
import { HomeserverInfo, WaterheatersInfo } from "../types";
import { API_URL } from "../../config";

export const UserDetailsStartAction = (): UserDetailsStart => {
    return {
        type: USER_DETAILS_START,
    }
}

export const UserDetailsSuccessAction = (waterheaters: WaterheatersInfo[], total: number, homeservers: HomeserverInfo[], user: UserDetailsInfo[]): UserDetailsSuccess => {
    return {
        type: USER_DETAILS_SUCCESS,
        payload: { waterheaters, total, homeservers, user }
    }
}

export const UserDetailsErrorAction = (error: string): UserDetailsError => {
    return {
        type: USER_DETAILS_ERROR,
        payload: { error }
    }
}

interface UserDetailsResponse {
    total: number;
    waterheaters: WaterheatersInfo[];
    homeservers: HomeserverInfo[];
    user: UserDetailsInfo[];
}

export const getUserdetailsByName = (uid: string) => async (dispatch: Dispatch<UserDetailsActions>) => {
    const { Login: { userInfo } } = ApplicationStore.getState();
    if (!userInfo) {
        return;
    }
    dispatch(UserDetailsStartAction());
    const { token } = userInfo;

    try {
        const res = await fetch(API_URL + "users/" + encodeURIComponent(uid), {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "authorization": "bearer " + token,
            },
        });
        if (res.status === 200) {
            const data = await res.json();
            const { waterheaters, total, homeservers, user } = data as UserDetailsResponse;
            dispatch(UserDetailsSuccessAction(waterheaters, total, homeservers, user));
        } else {
            await dispatch<any>(checkUnauthorized(res));
            dispatch(UserDetailsErrorAction(res.status.toString()));
        }
    } catch (e) {
        dispatch(UserDetailsErrorAction(e.toString()))
    }
}   