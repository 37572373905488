import { Dispatch } from "redux";
import { GetHomeserverDetailsActions, GET_HOME_SERVER_DETAILSSTART, GetHomeserverDetailsStart, GetHomeserverDetailsError, GET_HOME_SERVER_DETAILSERROR, GetHomeserverDetailsSuccess, GET_HOME_SERVER_DETAILSSUCCESS } from "./types";
import { ApplicationStore } from "../..";
import { checkUnauthorized } from "../authentication/actions";
import { HomeserverInfo, WaterheatersInfo } from "../types";
import { UserInfo } from "../types";
import { API_URL } from "../../config";

export const GetHomeserverDetailsStartAction = (): GetHomeserverDetailsStart => {
    return {
        type: GET_HOME_SERVER_DETAILSSTART,
    }
}

export const GetHomeserverDetailsSuccessAction = (waterheaters: WaterheatersInfo[], total: number, homeserver: HomeserverInfo, users: UserInfo[]): GetHomeserverDetailsSuccess => {
    return {
        type: GET_HOME_SERVER_DETAILSSUCCESS,
        payload: { waterheaters, total, homeserver, users }
    }
}

export const GetHomeserverDetailsErrorAction = (error: string): GetHomeserverDetailsError => {
    return {
        type: GET_HOME_SERVER_DETAILSERROR,
        payload: { error }
    }
}

interface GetHomeserverDetailsResponse {
    total: number;
    waterheaters: WaterheatersInfo[];
    homeserver: HomeserverInfo;
    users: UserInfo[];
}

export const getHomeserverDetails = (hid: number) => async (dispatch: Dispatch<GetHomeserverDetailsActions>) => {
    const { Login: { userInfo } } = ApplicationStore.getState();
    if (!userInfo) {
        return;
    }
    dispatch(GetHomeserverDetailsStartAction());
    const { token } = userInfo;

    try {
        const res = await fetch(API_URL + "homeservers/" + hid, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "authorization": "bearer " + token,
            },
        });
        if (res.status === 200) {
            const data = await res.json();
            const { waterheaters, total, homeserver, users } = data as GetHomeserverDetailsResponse;
            dispatch(GetHomeserverDetailsSuccessAction(waterheaters, total, homeserver, users));
        } else {
            await dispatch<any>(checkUnauthorized(res));
            dispatch(GetHomeserverDetailsErrorAction(res.status.toString()));
        }
    } catch (e) {
        dispatch(GetHomeserverDetailsErrorAction(e.toString()))
    }
}   