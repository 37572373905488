import { HomeserverInfo, WaterheatersInfo } from "../types";
import { UserInfo } from "../types";

export interface GetHomeserverDetailsState {
    loading: boolean;
    error?: string;
    waterheaters: WaterheatersInfo[];
    homeserver: HomeserverInfo;
    users: UserInfo[];
    total: number;
}

export const GET_HOME_SERVER_DETAILSSTART = "GET_HOME_SERVER_DETAILSSTART";
export const GET_HOME_SERVER_DETAILSSUCCESS = "GET_HOME_SERVER_DETAILSSUCCESS";
export const GET_HOME_SERVER_DETAILSERROR = "GET_HOME_SERVER_DETAILSERROR";

export interface GetHomeserverDetailsStart {
    type: typeof GET_HOME_SERVER_DETAILSSTART;
}

export interface GetHomeserverDetailsSuccess {
    type: typeof GET_HOME_SERVER_DETAILSSUCCESS;
    payload: {
        waterheaters: WaterheatersInfo[];
        total: number;

        homeserver: HomeserverInfo;
        users: UserInfo[];
    }
}

export interface GetHomeserverDetailsError {
    type: typeof GET_HOME_SERVER_DETAILSERROR;
    payload: {
        error: string;
    }
}

export type GetHomeserverDetailsActions = GetHomeserverDetailsStart | GetHomeserverDetailsSuccess | GetHomeserverDetailsError;