import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {
  LinearProgress,
  Divider,
  Typography,
  Box,
  makeStyles,
  Theme,
  createStyles,
  Tabs,
  Tab,
} from "@material-ui/core";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AppState } from "../../store";
import { getHomeserverDetails } from "../../store/getHomeserverDetails/actions";
import { DeleteUser } from "../users/DeleteUser";
import { DeleteHomeserver } from "../homeserver/DeleteHomeserver";
import { DeleteWaterheaters } from "./DeleteWaterheaters";
import { UserDetailsAsText } from "../users/UserDetailsAsText";

export interface WaterHeaterDetailsProps {
  homeServerId: number;
}

const getFromStoreMapping = ({ Waterheaters }: AppState) => {
  return Waterheaters;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      fontWeight: "lighter",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    list: {
      width: 250,
    },
    tableCellHead: {
      width: "50%",
      textAlign: "right",
      paddingRight: theme.spacing(1),
    },
    pagination: {
      paddingTop: 10,
      justifyContent: "flex-end",
    },
    button: {
      paddingLeft: 20,
    },
  }),
);

type TabValues = "homeserver" | "waterheater" | "user";

export function WaterHeaterDetails(props: WaterHeaterDetailsProps) {
  const { homeServerId } = props;
  const { waterheaters, loading, homeserver, users } = useSelector(
    getFromStoreMapping,
    shallowEqual,
  );
  const {
    home_server_name,
    home_server_device_id,
    state,
    internet_connection_status,
    internet_connection_status_change_timestamp,
  } = homeserver;
  const classes = useStyles();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (homeServerId) {
      dispatch(getHomeserverDetails(homeServerId));
    }
  }, [homeServerId, dispatch]);

  const [activeTabValue, setActiveTab] = useState<TabValues>("homeserver");

  const childs = waterheaters.map(
    ({ waterheater_device_id, remote_device_id, name }) => {
      return (
        <List key={waterheater_device_id}>
          <ListItem>
            <ListItemText>
              <b>Durchlauferhitzer ID: </b> {waterheater_device_id}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>remote device ID: </b> {remote_device_id}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemText>
              <b>Name: </b> {name}
            </ListItemText>
          </ListItem>
          <DeleteWaterheaters />
          <Divider />
        </List>
      );
    },
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: TabValues) => {
    event.stopPropagation();
    event.preventDefault();
    setActiveTab(newValue);
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant="h4" color="primary" className={classes.paper}>
          Details von {home_server_name}
        </Typography>
      </Box>

      <Tabs
        value={activeTabValue}
        centered
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="disabled tabs example"
      >
        <Tab disableFocusRipple value="homeserver" label="Homeserver" />
        <Tab disableFocusRipple value="user" label="Nutzer" />
        <Tab disableFocusRipple value="waterheater" label="Durchlauferhitzer" />
      </Tabs>
      <>
        {activeTabValue === "homeserver" ? (
          loading ? (
            <LinearProgress color="primary" />
          ) : (
            <>
              <List>
                <ListItem>
                  <Typography>
                    <b>Homeserver Name: </b>
                    {home_server_name}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    <b>Homeserver Device ID: </b>
                    {home_server_device_id}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    <b>Status: </b>
                    {state}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    <b>Internet Verbindung: </b>
                    {internet_connection_status}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography>
                    <b>Internet Verbindung Status Änderung: </b>
                    {internet_connection_status_change_timestamp}
                  </Typography>
                </ListItem>
              </List>
              <DeleteHomeserver />
              <Divider />
            </>
          )
        ) : null}

        {activeTabValue === "user" ? (
          <>
            <List>
              {users.map(user => {
                return (
                  <ListItemText className={classes.button} key={user.username}>
                    <UserDetailsAsText {...user} />
                  </ListItemText>
                );
              })}
            </List>
            <DeleteUser />
            <Divider />
          </>
        ) : null}

        {activeTabValue === "waterheater" ? (
          loading ? (
            <LinearProgress color="primary" />
          ) : (
            <>
              <List>{childs}</List>
            </>
          )
        ) : null}
      </>
      <Button
        className={classes.button}
        component="a"
        href={document.location.origin + "/HomeserverOverview"}
        size="large"
      >
        Zurück zur Übersicht
      </Button>
    </>
  );
}
