import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { Route, Switch } from "react-router";
import { CssBaseline } from '@material-ui/core';
import { theme } from './styling/clageTheme';
import { BrowserRouter } from 'react-router-dom';
import { Dashboard } from './Dashboard';
import { LogIn } from './Login';
import requireAuth from './requireAuth';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
});


export default function App(this: any) {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Switch>}
          <Route component={LogIn} path={"/LogIn"}></Route>
          // @ts-ignore
          <Route component={requireAuth(Dashboard)} path={"/:view?/:detailId?"}></Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}