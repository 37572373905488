import React from "react";
import { Table, TableBody, TableRow, TableCell } from "@material-ui/core";

export function UserTable({ children }: { children: JSX.Element }) {
  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>
            <b>Nutzer Name</b>
          </TableCell>
          <TableCell>
            <b>Email Adresse</b>
          </TableCell>
          <TableCell>
            <b>Email verifiziert</b>
          </TableCell>
          <TableCell>
            <b>user create date</b>
          </TableCell>
          <TableCell>
            <b>User last modified date</b>
          </TableCell>
          <TableCell>
            <b>enabled</b>
          </TableCell>
          <TableCell>
            <b>Nutzer Status</b>
          </TableCell>
        </TableRow>
        {children}
      </TableBody>
    </Table>
  );
}
