import React from "react";
import { Table, TableBody, TableRow, TableCell } from "@material-ui/core";

export function HomeserverTable({ children }: { children: JSX.Element }) {
  return (
    <Table size="small">
      <TableBody>
        <TableRow>
          <TableCell>
            <b>Homeserver Name</b>
          </TableCell>
          <TableCell>
            <b>Homeserver ID</b>
          </TableCell>
          <TableCell>
            <b>Status</b>
          </TableCell>
          <TableCell>
            <b>Internet Verbindung</b>
          </TableCell>
          <TableCell>
            <b>Internet Verbindungs Status Änderung</b>
          </TableCell>
        </TableRow>
        {children}
      </TableBody>
    </Table>
  );
}
