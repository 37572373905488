import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider as ReduxProvider } from "react-redux";
import { createStore, applyMiddleware, StoreEnhancer, compose } from "redux";
import { rootReducer, AppState } from "./store";
import ReduxThunk from "redux-thunk";
import logger from "redux-logger";
import "./styling/paginationTheme.css";

const composed = applyMiddleware(ReduxThunk, logger);

const composeVersion: (
  middleware: StoreEnhancer,
) => StoreEnhancer<AppState> = compose;
const enhancers = composeVersion(composed);

export const ApplicationStore = createStore(rootReducer, enhancers);
//ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
  <ReduxProvider store={ApplicationStore}>
    <App></App>
  </ReduxProvider>,
  document.getElementById("root"),
);
serviceWorker.unregister();
