import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import { WaterheatersInfo } from "../../store/types";

interface WaterheatersTblRowItemsProps {
  durchlauferhitzer: WaterheatersInfo[];
  onClick: (home_server_id: number) => void;
}

export function WaterheatersTblRowItems({
  durchlauferhitzer,
  onClick,
}: WaterheatersTblRowItemsProps) {
  const childs = durchlauferhitzer.map(element => {
    const {
      waterheater_device_id,
      remote_device_id,
      name,
      home_server,
    } = element;
    return (
      <TableRow
        key={waterheater_device_id}
        style={{ cursor: "pointer" }}
        onClick={onClick.bind(null, home_server)}
      >
        <TableCell style={{ width: "10%" }}>{remote_device_id}</TableCell>
        <TableCell style={{ width: "10%" }}>{name}</TableCell>
        <TableCell style={{ width: "10%" }}>{home_server}</TableCell>
      </TableRow>
    );
  });
  return <>{childs}</>;
}
