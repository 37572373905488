import React from "react";
import { WaterHeaterDetails } from "../../components/waterheaters/WaterHeaterDetails";
import { withRouter, RouteComponentProps } from "react-router";
import { ClageRoutes } from "../../Dashboard";
import { HomeserverOverviewRouted } from "./HomeserverOverview";

export const HomeserverOverviewSwitch = withRouter(
  (props: RouteComponentProps<ClageRoutes>) => {
    const detailId = parseInt(props.match.params.detailId, 10);
    if (Number.isFinite(detailId)) {
      return <WaterHeaterDetails homeServerId={detailId} />;
    } else {
      return <HomeserverOverviewRouted />;
    }
  },
);
