import { combineReducers } from 'redux';
import { LoginReducer } from './authentication/reducer';
import { HomeserverReducer } from './getHomeserver/reducer';
import { DeleteHomeserverReducer } from './deleteHomeserver/reducer';
import { AuthenticationState } from "./authentication/types";
import { GetHomeserverState } from './getHomeserver/types';
import { SearchWaterheatersReducer } from './searchWaterheaters/reducer';
import { SearchWaterheaterState } from './searchWaterheaters/types';
import { GetHomeserverDetailsReducer } from './getHomeserverDetails/reducer';
import { GetHomeserverDetailsState } from './getHomeserverDetails/types'
import { UserReducer } from './searchUser/reducer';
import { UserState } from './searchUser/types';
import { DeleteHomeserverState } from './deleteHomeserver/types';
import { UserDetailsState } from './getUserdetailsByName/types';
import { UserDetailsReducer } from './getUserdetailsByName/reducer';

export interface ApplicationStore {
    Login: AuthenticationState;
    DeleteHomeserver: DeleteHomeserverState;
    Homeserver: GetHomeserverState;
    Durchlauferhitzer: SearchWaterheaterState;
    Waterheaters: GetHomeserverDetailsState;
    User: UserState;
    UserDetails: UserDetailsState;
}

export const rootReducer = combineReducers<ApplicationStore>({
    Login: LoginReducer,
    Homeserver: HomeserverReducer,
    DeleteHomeserver: DeleteHomeserverReducer,
    Durchlauferhitzer: SearchWaterheatersReducer,
    Waterheaters: GetHomeserverDetailsReducer,
    User: UserReducer,
    UserDetails: UserDetailsReducer,
})

export type AppState = ReturnType<typeof rootReducer>;