import React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { ClageRoutes } from "../../Dashboard";
import { UserDetails } from "./UserDetails";
import { Users } from "./Users";
export const UserOverviewSwitch = withRouter(
  (props: RouteComponentProps<ClageRoutes>) => {
    const detailId = props.match.params.detailId;
    if (detailId) {
      return <UserDetails username={detailId} />;
    } else {
      return <Users />;
    }
  },
);
