import React from "react";
import { Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import UnCheckIcon from "@material-ui/icons/Close";
import { CSSProperties } from "@material-ui/styles";
import { UserInfo } from "../../store/types";

export interface WaterHeaterDetailsProps {
  homeServerId: number;
}

const iconStyle: CSSProperties = { fontSize: 20 };

export function UserDetailsAsText({
  username,
  emailVerified,
  email,
  userCreateDate,
  userLastModifiedDate,
  enabled,
  userStatus,
}: UserInfo) {
  return (
    <>
      <Typography>
        <b>Nutzer Name: </b>
        {username}
      </Typography>
      <Typography>
        <b>Email Adresse: </b>
        {email}
      </Typography>
      <Typography>
        <b>Email verifiziert: </b>
        {emailVerified ? (
          <CheckIcon style={iconStyle} />
        ) : (
          <UnCheckIcon style={iconStyle} />
        )}
      </Typography>
      <Typography>
        <b>user create date: </b>
        {userCreateDate}
      </Typography>
      <Typography>
        <b>User last modified date: </b>
        {userLastModifiedDate}
      </Typography>
      <Typography>
        <b>enabled: </b>
        {enabled ? (
          <CheckIcon style={iconStyle} />
        ) : (
          <UnCheckIcon style={iconStyle} />
        )}
      </Typography>
      <Typography>
        <b>Nutzer Status: </b>
        {userStatus}
      </Typography>
    </>
  );
}
