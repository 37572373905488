import React from "react";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogContent,
  DialogActions,
  LinearProgress,
  Divider,
} from "@material-ui/core";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { AppState } from "../../store";
import { getHomeserverDetails } from "../../store/getHomeserverDetails/actions";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";

const Transition = React.forwardRef<unknown, TransitionProps>(
  function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

export interface WaterHeaterDetailsPageProps {
  id?: number;
  onClose: () => void;
}

const getFromStoreMapping = ({ Waterheaters }: AppState) => {
  return Waterheaters;
};

export function WaterHeaterDetailsPage(props: WaterHeaterDetailsPageProps) {
  const { onClose, id } = props;

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (id) {
      dispatch(getHomeserverDetails(id));
    }
  }, [id, dispatch]);

  const { waterheaters, total, loading } = useSelector(
    getFromStoreMapping,
    shallowEqual,
  );

  console.log({ waterheaters });

  const childs = waterheaters.map(
    ({ waterheater_device_id, remote_device_id, name }) => {
      return (
        <List>
          <ListItem key={waterheater_device_id}>
            <ListItemText>
              <b>Durchlauferhitzer ID: </b> {waterheater_device_id}
            </ListItemText>
          </ListItem>
          <ListItem key={waterheater_device_id}>
            <ListItemText>
              <b>remote device ID: </b> {remote_device_id}
            </ListItemText>
          </ListItem>
          <ListItem key={waterheater_device_id}>
            <ListItemText>
              <b>Name: </b> {name}
            </ListItemText>
          </ListItem>
          <Divider />
        </List>
      );
    },
  );

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={!!id}
      TransitionComponent={Transition}
    >
      <DialogTitle id="simple-dialog-title">
        Durchlauferhitzer Anzahl: {total}
      </DialogTitle>
      <DialogContent>
        {loading ? <LinearProgress color="primary" /> : <List>{childs}</List>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" autoFocus>
          Schließen
        </Button>
        <Button onClick={onClose} color="secondary" autoFocus>
          Löschen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
