import { GET_HOME_SERVER_DETAILSSTART, GET_HOME_SERVER_DETAILSSUCCESS, GET_HOME_SERVER_DETAILSERROR, GetHomeserverDetailsState, GetHomeserverDetailsActions } from './types';

const initialState: GetHomeserverDetailsState = {
    loading: false,
    waterheaters: [],
    homeserver: { home_server_device_id: "", id: 0, state: "", home_server_name: "", internet_connection_status: "", internet_connection_status_change_timestamp: "" },
    users: [],
    total: 0,
}

export const GetHomeserverDetailsReducer = (state: GetHomeserverDetailsState = initialState, action: GetHomeserverDetailsActions): GetHomeserverDetailsState => {
    switch (action.type) {
        case GET_HOME_SERVER_DETAILSSTART: return {
            ...state,
            loading: true
        }
        case GET_HOME_SERVER_DETAILSSUCCESS: return {
            ...state,
            waterheaters: action.payload.waterheaters,
            total: action.payload.total,
            homeserver: action.payload.homeserver,
            users: action.payload.users,

            loading: false,
            error: undefined
        }
        case GET_HOME_SERVER_DETAILSERROR: return {
            ...state,
            loading: false,
            waterheaters: [],
            total: 0,
            error: action.payload.error
        }
        default: return state
    }
}