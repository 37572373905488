export interface SearchWaterheatersResult {
    waterheater_device_id: number;
    remote_device_id: string;
    name: string;
    home_server: number;
}

export interface SearchWaterheaterState {
    loading: boolean;
    error?: string;
    durchlauferhitzer: SearchWaterheatersResult[];
    total: number;
}

export const SEARCH_WATERHEATERS_START = "SEARCH_WATERHEATERS_START";
export const SEARCH_WATERHEATERS_SUCCESS = "SEARCH_WATERHEATERS_SUCCESS";
export const SEARCH_WATERHEATERS_ERROR = "SEARCH_WATERHEATERS_ERROR";

export interface SearchWaterheatersStart {
    type: typeof SEARCH_WATERHEATERS_START;
}

export interface SearchWaterheatersSuccess {
    type: typeof SEARCH_WATERHEATERS_SUCCESS;
    payload: {
        durchlauferhitzer: SearchWaterheatersResult[];
        total: number;
    }
}

export interface SearchWaterheatersErrorError {
    type: typeof SEARCH_WATERHEATERS_ERROR;
    payload: {
        error: string;
    };
}

export type SearchWaterheatersActions = SearchWaterheatersStart | SearchWaterheatersSuccess | SearchWaterheatersErrorError;