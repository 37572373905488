export interface AuthUserInfo {
    token: string;
    lastName: string;
    firstName: string;
    email: string;
}

export interface AuthenticationState {
    loading: boolean;
    error?: string;
    userInfo?: AuthUserInfo;
}

export const LOGIN_START = 'LOGIN_START';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export interface LoginStart {
    type: typeof LOGIN_START;
}

export interface LoginError {
    type: typeof LOGIN_ERROR;
    payload: {
        error: string;
    };
}

export interface LoginSuccess {
    type: typeof LOGIN_SUCCESS;
    payload: {
        userInfo: AuthUserInfo;
    };
}
export interface LogoutSuccess {
    type: typeof LOGOUT_SUCCESS;
}

export type LoginActions = LoginStart | LoginError | LoginSuccess | LogoutSuccess;