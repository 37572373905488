import { USER_DETAILS_START, USER_DETAILS_SUCCESS, USER_DETAILS_ERROR, UserDetailsState, UserDetailsActions } from './types';

const initialState: UserDetailsState = {
    loading: false,
    waterheaters: [],
    homeservers: [],
    user: [],
    paginationToken: "",
    total: 0,
}

export const UserDetailsReducer = (state: UserDetailsState = initialState, action: UserDetailsActions): UserDetailsState => {
    switch (action.type) {
        case USER_DETAILS_START: return {
            ...state,
            loading: true
        }
        case USER_DETAILS_SUCCESS: return {
            ...state,
            waterheaters: action.payload.waterheaters,
            total: action.payload.total,
            homeservers: action.payload.homeservers,
            user: action.payload.user,

            loading: false,
            error: undefined
        }
        case USER_DETAILS_ERROR: return {
            ...state,
            loading: false,
            waterheaters: [],
            total: 0,
            error: action.payload.error
        }
        default: return state
    }
}