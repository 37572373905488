import { UserInfo } from "../types";

export interface UserState {
    loading: boolean;
    error?: string;
    user: UserInfo[];
    paginationToken: string;
    total: number;
}

export const SEARCH_USER_START = "SEARCH_USER_START";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_ERROR = "SEARCH_USER_ERROR";

export interface SearchUserStart {
    type: typeof SEARCH_USER_START;
}

export interface SearchUserSuccess {
    type: typeof SEARCH_USER_SUCCESS;
    payload: {
        user: UserInfo[];
        paginationToken: string
    };
}

export interface SearchUserError {
    type: typeof SEARCH_USER_ERROR;
    payload: {
        error: string;
    };
}

export type SearchUserActions = SearchUserStart | SearchUserSuccess | SearchUserError;