import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import classnames from "classnames";
import CLAGE from "./CLAGE-Transparent.png";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "./store";
import { doLogin } from "./store/authentication/actions";
import { ErrorBlock } from "./ErrorBlock";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    margin: {
      margin: theme.spacing(1),
    },
    textField: {},
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    container: {
      width: "50%",
      maxWidth: 400,
      margin: "0 auto",
    },
    fab: {
      float: "right",
      marginTop: theme.spacing(1),
      boxShadow: "none",
    },
  }),
);

const storeToProps = ({ Login }: AppState) => {
  return Login;
};

export function LogIn() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const Login = useSelector(storeToProps);
  const { loading, error, userInfo } = Login;
  if (userInfo) {
    document.location.replace(
      document.location.origin + "/DurchlauferhitzerOverview",
    );
  }

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const onChangePWD = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const onLogin = () => {
    dispatch(doLogin(email, password));
  };

  return (
    <div className={classes.container}>
      <img style={{ width: 400, height: 150 }} src={CLAGE} alt="Logo"></img>

      <ErrorBlock error={error} />

      <TextField
        id="input-with-icon-grid"
        label="Email Adresse"
        onChange={onChangeEmail}
        className={classes.textField}
        margin="normal"
        variant="outlined"
        fullWidth={true}
        value={email}
      />

      <TextField
        id="outlined-password-input"
        label="Password"
        className={classes.textField}
        type="password"
        autoComplete="current-password"
        margin="normal"
        variant="outlined"
        fullWidth={true}
        value={password}
        onChange={onChangePWD}
      />

      <Button
        variant="contained"
        color="primary"
        onClick={onLogin}
        disabled={loading === true}
        aria-label="like"
        className={classnames(classes.textField, classes.fab)}
      >
        Anmelden
      </Button>
    </div>
  );
}
