import React from "react";
import { TableRow, TableCell } from "@material-ui/core";

import CheckIcon from "@material-ui/icons/Check";
import UnCheckIcon from "@material-ui/icons/Close";
import { UserInfo } from "../../store/types";

interface UserOverviewRoutedProps {
  user: UserInfo[];
  onClick: (user: UserInfo) => void;
}
export function UserTblRowItems({ user, onClick }: UserOverviewRoutedProps) {
  const userItems = user.map(element => {
    const {
      username,
      emailVerified,
      email,
      userCreateDate,
      userLastModifiedDate,
      enabled,
      userStatus,
    } = element;
    return (
      <TableRow
        style={{ cursor: "pointer" }}
        key={username}
        onClick={onClick.bind(null, element)}
      >
        <TableCell style={{ width: "10%" }}>
          <a
            style={{ display: "none" }}
            href={"/UserOverview/" + username}
            id={"wid" + username}
          >
            Bla
          </a>
          {username}
        </TableCell>
        <TableCell style={{ width: "10%" }}>{email}</TableCell>
        <TableCell style={{ width: "10%" }}>
          {emailVerified ? <CheckIcon /> : <UnCheckIcon />}
        </TableCell>
        <TableCell style={{ width: "10%" }}>{userCreateDate}</TableCell>
        <TableCell style={{ width: "10%" }}>{userLastModifiedDate}</TableCell>
        <TableCell style={{ width: "10%" }}>
          {enabled ? <CheckIcon /> : <UnCheckIcon />}
        </TableCell>
        <TableCell style={{ width: "10%" }}>{userStatus}</TableCell>
      </TableRow>
    );
  });

  return <>{userItems}</>;
}
