import React, { useState } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Box,
  Typography,
  TableRow,
  TableCell,
  LinearProgress,
  TextField,
  Button,
} from "@material-ui/core";

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { searchUser } from "../../store/searchUser/actions";
import { AppState } from "../../store";
import { ErrorBlock } from "../../ErrorBlock";
import debounce from "lodash/debounce";
import "../../styling/paginationTheme.css";
import { UserTable } from "./UserTable";
import { UserTblRowItems } from "./UserTblRowItems";
import { UserInfo } from "../../store/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      fontWeight: "lighter",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    list: {
      width: 250,
    },
    tableCellHead: {
      width: "50%",
      textAlign: "right",
      paddingRight: theme.spacing(1),
    },
    pagination: {
      paddingTop: 10,
      justifyContent: "flex-end",
    },
    paginationButton: {
      textAlign: "center",
    },
  }),
);

const getFromStoreMapping = ({ User }: AppState) => {
  return User;
};

export function Users() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(0);
  // const [detailsPage, setDetailsPage] = useState<string | undefined>(undefined);

  const userPerPage = 10;

  const {
    user: allUsers,
    total,
    loading,
    error,
    paginationToken,
  } = useSelector(getFromStoreMapping, shallowEqual);

  const end = (currentPage + 1) * userPerPage;
  const start = currentPage * userPerPage;
  const user = allUsers.slice(start, end);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(total / userPerPage); i++) {
    pageNumbers.push(i);
  }

  const [searchValue, setSearchValue] = useState("");
  const [searchValueDebounced, setSearchValueDebounced] = useState("");

  const onChangeQ = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const setDebounced = React.useCallback(
    debounce((value: string) => {
      setSearchValueDebounced(value);
    }, 1000),
    [],
  );

  React.useEffect(() => {
    setDebounced(searchValue);
  }, [searchValue, setDebounced]);

  React.useEffect(() => {
    dispatch(searchUser(searchValueDebounced, userPerPage, ""));
    setCurrentPage(0);
  }, [searchValueDebounced, dispatch]);

  const openDetailView = ({ username }: UserInfo) => {
    document.location.href =
      document.location.origin +
      "/UserOverview/" +
      encodeURIComponent(username);
  };

  const nextPage = () => {
    if (allUsers.length >= end) {
      setCurrentPage(currentPage + 1);
    }
    if (allUsers.length === (currentPage + 1) * userPerPage) {
      dispatch(searchUser(searchValueDebounced, userPerPage, paginationToken));
    }
  };

  const prevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <div className={classes.root}>
      <Box mb={3}>
        <Typography variant="h4" color="primary" className={classes.paper}>
          Nutzer Ergebnisse
        </Typography>
      </Box>

      <TextField
        fullWidth={true}
        onChange={onChangeQ}
        placeholder="Suche nach Nutzer Namen oder Email Adresse. Groß- und Kleinschreibung beachten "
        value={searchValue}
      ></TextField>

      <ErrorBlock error={error} />

      {loading ? (
        <LinearProgress color="primary" />
      ) : (
        <>
          <UserTable>
            {user.length === 0 ? (
              <TableRow>
                <TableCell colSpan={2} className={classes.tableCellHead}>
                  Keine Nutzer gefunden
                </TableCell>
              </TableRow>
            ) : (
              <UserTblRowItems onClick={openDetailView} user={user} />
            )}
          </UserTable>
          <div className={classes.paginationButton}>
            <Button
              color="primary"
              onClick={prevPage}
              disabled={currentPage === 0}
            >
              Zurück
            </Button>
            <Button
              color="primary"
              onClick={nextPage}
              disabled={allUsers.length < end}
            >
              Weiter
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
