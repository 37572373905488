import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../users/DeleteUser";
export function DeleteWaterheaters() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={classes.button}>
      <Button variant="outlined" onClick={handleClickOpen}>
        Durchlauferhitzer Löschen
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.text} id="form-dialog-title">
          Sind Sie sich sicher den Durchlauferhitzer: zu löschen?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Die Löschung des Durchlauferhitzers kann nicht rückgängig gemacht
            werden. Dieser Durchlauferhitzer wird gelöscht, wenn Sie auf
            unwiderruflich löschen drücken.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Als Bestätigung die ID des Durchlauferhitzers eingeben"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className={classes.text}>
            Abbrechen
          </Button>
          <Button onClick={handleClose} color="secondary">
            unwiderruflich löschen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
