import { Dispatch } from "redux";
import { validateEmail } from "../../helpers/emailValidation";

import * as Cookies from "es-cookie";
import { LoginActions, LoginStart, LOGIN_START, LoginError, LOGIN_ERROR, AuthUserInfo, LoginSuccess, LOGIN_SUCCESS, LogoutSuccess, LOGOUT_SUCCESS } from "./types";
import { ApplicationStore } from "../..";
import { API_URL } from "../../config";

export const LoginStartAction = (): LoginStart => {
  return {
    type: LOGIN_START
  };
};

export const LoginErrorAction = (error: string): LoginError => {
  return {
    type: LOGIN_ERROR,
    payload: { error }
  };
};

export const LoginSuccessAction = (userInfo: AuthUserInfo): LoginSuccess => {
  return {
    type: LOGIN_SUCCESS,
    payload: { userInfo }
  };
};

export const LogoutSuccessAction = (): LogoutSuccess => {
  return {
    type: LOGOUT_SUCCESS
  };
};

export const doLogout = (doNotServerLogout?: boolean) => async (dispatch: Dispatch<LoginActions>) => {
  const {
    Login: { userInfo }
  } = ApplicationStore.getState();
  if (!userInfo) {
    return;
  }
  const { token } = userInfo;
  if (!doNotServerLogout) {

    await fetch(API_URL + "logout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "bearer " + token
      }
    });
  }
  // let tenSecFromNow = new Date();
  // tenSecFromNow.setSeconds(tenSecFromNow.getSeconds() + 10)
  Cookies.remove("authentication", { expires: 1 });
  document.location.replace(document.location.origin);
  dispatch(LogoutSuccessAction());
};

export const checkUnauthorized = (res: Response) => async (dispatch: Dispatch<LoginActions>) => {
  if (res.status === 401) {
    await dispatch<any>(doLogout(true));
    return false;
  }
  return true;
}

export const doLogin = (mail: string, password: string) => async (
  dispatch: Dispatch<LoginActions>
) => {
  dispatch(LoginStartAction());
  if (mail === "" || !validateEmail(mail)) {
    dispatch(LoginErrorAction("Keine gültige Email"));
    return;
  }

  try {
    const res = await fetch(API_URL + "login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email: mail, password })
    });
    if (res.status === 200) {
      const data = await res.json();
      const { accessToken: token, email, firstName, lastName } = data;
      // let tenSecFromNow = new Date();
      // tenSecFromNow.setSeconds(tenSecFromNow.getSeconds() + 10)
      Cookies.set(
        "authentication",
        JSON.stringify({ email, token, firstName, lastName }),
        { expires: 1 }
      );
      dispatch(LoginSuccessAction({ email, token, firstName, lastName }));
    } else {
      dispatch(LoginErrorAction(res.statusText));
    }
  } catch (e) {
    dispatch(LoginErrorAction(e.toString()));
  }
};