import { HomeserverInfo } from "../types";


export interface GetHomeserverState {
  loading: boolean;
  error?: string;
  homeservers: HomeserverInfo[];
  total: number;
}

export const GET_HOMESERVER_START = "GET_HOMESERVER_START";
export const GET_HOMESERVER_SUCCESS = "GET_HOMESERVER_SUCCESS";
export const GET_HOMESERVER__ERROR = "GET_HOMESERVER__ERROR";

export interface GetHomeserverStart {
  type: typeof GET_HOMESERVER_START;
}

export interface GetHomeserverSuccess {
  type: typeof GET_HOMESERVER_SUCCESS;
  payload: {
    homeservers: HomeserverInfo[];
    total: number;
  };
}

export interface GetHomeserverError {
  type: typeof GET_HOMESERVER__ERROR;
  payload: {
    error: string;
  };
}

export type GetHomeserverActions = GetHomeserverStart | GetHomeserverSuccess | GetHomeserverError;
