import { DELETE_HOMESERVER_START, DELETE_HOMESERVER_SUCCESS, DELETE_HOMESERVER_ERROR, DeleteHomeserverState, DeleteHomeserverActions, } from "./types";

const initalState: DeleteHomeserverState = {
    loading: false,
    homeservers: [],
    total: 0,
}

export const DeleteHomeserverReducer = (state: DeleteHomeserverState = initalState, action: DeleteHomeserverActions): DeleteHomeserverState => {
    switch (action.type) {
        case DELETE_HOMESERVER_START: return {
            ...state,
            loading: true
        }
        case DELETE_HOMESERVER_SUCCESS: return {
            ...state,
            homeservers: action.payload.homeservers,
            total: action.payload.total,
            loading: false,
            error: undefined
        }
        case DELETE_HOMESERVER_ERROR: return {
            ...state,
            loading: false,
            homeservers: [],
            total: 0,
            error: action.payload.error
        }
        default: return state
    }
}