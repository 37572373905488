import React, { ReactNode } from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Typography,
  AppBar,
  Toolbar,
  Breadcrumbs,
  List,
  ListItem,
  ListItemText,
  Drawer,
  Card,
  Divider,
  CardContent,
  CardActions,
  Grid,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { RouteComponentProps } from "react-router";
import { DurchlauferhitzerOverview } from "./components/waterheaters/Waterheaters";
import { UserOverviewSwitch } from "./components/users/UserOverviewSwitch";
import { HomeserverOverviewSwitch } from "./components/homeserver/HomeserverOverviewSwitch";
import CLAGE from "./CLAGE-Transparent.png";
import { useDispatch } from "react-redux";
import { doLogout } from "./store/authentication/actions";
import { DetailViewDurchlauferhitzer } from "./components/waterheaters/DetailViewDurchlauferhitzer";
import "./styling/drawerTheme.css";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      fontWeight: "lighter",
    },
    list: {
      width: 250,
    },
    tableCellHead: {
      fontWeight: "bold",
    },
    tableCell: {},
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
    },
    cover: {
      width: 225,
      height: 80,
    },
    button: {
      padding: theme.spacing(2),
      justifyContent: "flex-start",
    },
    hover: {
      "&:hover": {
        background: "#009d4vb",
      },
    },
    card: {
      maxHeight: 230,
      marginRight: 20,
      display: "inline-block",
    },
  }),
);

export interface ClageRoutes {
  view: string;
  detailId: string;
}

interface DashboardProps extends RouteComponentProps<ClageRoutes> {
  children?: ReactNode;
}

function NotFound(props: any) {
  const classes = useStyles();
  return (
    <Grid container={true}>
      <Grid item={true} md={4}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Nutzer
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Hier können Sie die Nutzer aufrufen und haben Einsicht in Details
              wie Email Adresse und Nutzername.
            </Typography>
          </CardContent>
          <CardActions>
            <ListItem button component="a" href="/UserOverview">
              <ListItemText primary="Nutzer Überblick"></ListItemText>
            </ListItem>
          </CardActions>
        </Card>
      </Grid>
      <Grid item={true} md={4}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Homeserver
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Hier können Sie die Homeserver aufrufen und haben Einsicht in
              Details wie Name und Id der Homeserver.
            </Typography>
          </CardContent>
          <CardActions>
            <ListItem button component="a" href="/HomeserverOverview">
              <ListItemText primary="Homeserver Überblick"></ListItemText>
            </ListItem>
          </CardActions>
        </Card>
      </Grid>
      <Grid item={true} md={4}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Durchlauferhitzer
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Hier können Sie die Durchlauferhitzer aufrufen und haben Einsicht
              in Details wie Name und Id der Durchlauferhitzer.
            </Typography>
          </CardContent>

          <CardActions>
            <ListItem button component="a" href="/DurchlauferhitzerOverview">
              <ListItemText primary="Durchlauferhitzer Überblick"></ListItemText>
            </ListItem>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

interface AppPathConfig {
  [key: string]: {
    component: JSX.Element;
    title: string;
    navTitle: string;
    isVisibleInMenu?: boolean;
  };
}

const ClageAppPaths: AppPathConfig = {
  DurchlauferhitzerOverview: {
    component: <DurchlauferhitzerOverview />,
    title: "Durchlauferhitzer Übersicht",
    navTitle: "Durchlauferhitzer",
  },
  UserOverview: {
    component: <UserOverviewSwitch />,
    title: "Nutzer Übersicht",
    navTitle: "Nutzer",
  },
  HomeserverOverview: {
    component: <HomeserverOverviewSwitch />,
    title: "Homeserver Übersicht",
    navTitle: "Homeserver",
  },
  DurchlauferhitzerDetailView: {
    component: <DetailViewDurchlauferhitzer />,
    title: "Durchlauferhitzer Detailansicht",
    navTitle: "DurchlauferhitzerDetailansicht",
  },
  NotFound: {
    component: <NotFound />,
    title: "Seite nicht gefunden",
    navTitle: "",
    isVisibleInMenu: false,
  },
};

export function Dashboard(props: DashboardProps) {
  const view = props.match.params.view;

  const viewConfig = ClageAppPaths[view] || ClageAppPaths["NotFound"];
  const dispatch = useDispatch();

  const { component, title: componentTitle } = viewConfig;

  const classes = useStyles();

  type DrawerSide = "left";

  const logoutAction = () => {
    dispatch(doLogout());
  };

  const sideList = (side: DrawerSide) => (
    <div className={classes.list} role="presentation">
      <List>
        <ListItem button component="a" href="/UserOverview">
          <ListItemText primary="Nutzer"></ListItemText>
        </ListItem>
      </List>

      <List>
        <ListItem button component="a" href="/HomeserverOverview">
          <ListItemText color="primary" primary="Homeserver"></ListItemText>
        </ListItem>
      </List>

      <List>
        <ListItem button component="a" href="/DurchlauferhitzerOverview">
          <ListItemText primary="Durchlauferhitzer"></ListItemText>
        </ListItem>
      </List>

      <Divider />

      <List>
        <ListItem button={true} onClick={logoutAction}>
          <ListItemText primary="Logout"></ListItemText>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense">
          <Breadcrumbs
            separator={
              <NavigateNextIcon style={{ color: "#fff" }} fontSize="small" />
            }
            aria-label="breadcrumb"
          >
            <Typography style={{ color: "#fff" }}>Überblick</Typography>
            <Typography style={{ color: "#fff" }}>{componentTitle}</Typography>
          </Breadcrumbs>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <img className={classes.cover} src={CLAGE} alt="Clage Logo"></img>

        {sideList("left")}
      </Drawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {component}
      </main>
    </div>
  );
}
